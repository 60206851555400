import * as React from 'react';
import { displayDate } from 'services/Date';
import { ToastContentProps } from 'react-toastify';
import classNames from 'classnames';

export type ToastMessageProps = Partial<ToastContentProps> & React.PropsWithChildren & {
    title?: string;
    message?: string;
};

export const ToastMessage: React.FC<ToastMessageProps> = ({ title, message, children, closeToast, toastProps }) => {
    let toastTitle = title;
    if (!title) {
        switch (toastProps?.type) {
            case 'error':
                toastTitle = 'Error';
                break;

            case 'warning':
                toastTitle = 'Warning';
                break;

            case 'success':
                toastTitle = 'Success';
                break;

            default:
                toastTitle = 'Information';
                break;
        }
    }

    return <div
        className={classNames(
            'toast notification fade show',
            {
                'notification-danger toast-danger': toastProps?.type === 'error',
                'notification-info toast-info': toastProps?.type === 'info',
                'notification-success toast-success': toastProps?.type === 'success',
                'notification-warning toast-warning': toastProps?.type === 'warning',
                'notification-primary toast-primary': toastProps?.type === 'default',
            },
        )}
    >
        <div className="notification-header">
            <span className="fw-medium me-auto">{toastTitle}</span>
            <span className="notification-timestamp">
                {displayDate(new Date(), 'With diff from now')}
            </span>
            <button type="button" className="btn-close" aria-label="Close" onClick={closeToast} />
        </div>
        {message || children
            ? <div
                className="notification-body text-primary"
                style={{ whiteSpace: 'pre-line' }}
            >
                {message && <div>{message}</div>}
                {children && <div>{children}</div>}
            </div>
            : null}
    </div>;
};
