import { useAppContext } from 'components/common/AppProvider';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { RoutePaths } from 'routes/RoutePaths';
import { SearchContractRequest } from 'services/ApiService/Franfinance/FranfinanceApiClient';
import { track, TrackedPageType } from 'services/Logger';
import { DEFAULT_SEARCH_CONTRACTS_FILTERS } from 'store/Contracts/ContractsReducer';
import { DEFAULT_SEARCH_FINANCIAL_REQUEST_FILTERS, SearchFinancialRequest } from 'store/Financial/FinancialReducer';
import { getRightToSeeFinancialRequests } from 'store/Financial/FinancialThunk';

interface HeaderListProps {
    className?: string,
    toggle?: () => void
}

export const HeaderList: React.FC<HeaderListProps> = ({ className, toggle }) => {
    const { t } = useTranslation();
    const { state: { contracts, appContext: { currentClient: { siren } }, financial }, dispatch } = useAppContext();

    useEffect(() => {
        if (siren) {
            dispatch(getRightToSeeFinancialRequests(siren));
        }
    }, [siren]);

    const handleClick = (pageName: string) => {
        track('ClickOnMenu', 'click on menu', { page: TrackedPageType.Menu, pageName });
    };

    const contractsSearchFilters = siren && contracts?.[siren]?.search?.filters || DEFAULT_SEARCH_CONTRACTS_FILTERS as SearchContractRequest;
    const financialRequestsFilters = siren && financial?.[siren]?.search?.filters || DEFAULT_SEARCH_FINANCIAL_REQUEST_FILTERS as SearchFinancialRequest;
    const canSeeFinancialRequests = siren && financial?.[siren]?.canSeeFinancialRequests?.data;

    return <ul className={className} data-bs-popper="static">
        <li className="navbar-item">
            <NavLink
                onClick={toggle}
                end
                to={RoutePaths.Home.url()}
                className="navbar-link"
                onMouseUp={() => handleClick('Home page')}
            >
                {RoutePaths.Home.title(t)}
            </NavLink>
        </li>
        <li className="navbar-item">
            <NavLink
                onClick={toggle}
                end
                to={RoutePaths.Contracts.url(contractsSearchFilters)}
                className="navbar-link"
                onMouseUp={() => handleClick('Your contracts')}
            >
                {t('menu:Titles.YourContracts')}
            </NavLink>
        </li>
        <li className="navbar-item">
            <NavLink
                onClick={toggle}
                end
                to={RoutePaths.Invoices.url()}
                className="navbar-link"
                onMouseUp={() => handleClick('Your billings')}
            >
                {t('menu:Titles.YourBillings')}
            </NavLink>
        </li>
        <li className="navbar-item">
            <NavLink
                onClick={toggle}
                end
                to={RoutePaths.CustomerRequests.url()}
                className="navbar-link"
                onMouseUp={() => handleClick('Steps')}
            >
                {t('menu:Titles.Steps')}
            </NavLink>
        </li>
        {canSeeFinancialRequests
            && <li className="navbar-item">
                <NavLink
                    onClick={toggle}
                    end
                    to={RoutePaths.Simulations.FinancialRequest.url(financialRequestsFilters)}
                    className="navbar-link"
                    onMouseUp={() => handleClick('Project')}
                >
                    {t('menu:Titles.Projects')}
                </NavLink>
            </li>
        }
    </ul >;
};