import { WidgetLazyLoader } from 'components/common/WidgetLazyLoader';
import { configuration } from 'config/constants';
import * as React from 'react';


declare global {
    namespace React {
        namespace JSX {
            interface IntrinsicElements {
                'sgwt-connect': any;
            }
        }
    }
}

export const SgwtConnect = () => {
    return <WidgetLazyLoader script={`${configuration.widgetCdnBaseUrl}/widgets/sgwt-connect/v4/sgwt-connect.js`}>
        <sgwt-connect />
    </WidgetLazyLoader>;
};
