import React from 'react';
import { toast } from 'react-toastify';
import { ToastMessage } from './ToastMessage';

export const toastError = (message: string, title?: string) =>
    toast.error(<ToastMessage
        title={title}
        message={message}
    />, {
        className: 'p-0',
        toastId: message,
    });

export const toastWarn = (message: string, title?: string) =>
    toast.warn(<ToastMessage
        title={title}
        message={message}
    />, {
        className: 'p-0',
        toastId: message,
    });

export const toastInfo = (message: string, title?: string) =>
    toast.info(<ToastMessage
        title={title}
        message={message}
    />, {
        className: 'p-0',
        toastId: message,
    });

export const toastSuccess = (message: string, title?: string) =>
    toast.success(<ToastMessage
        title={title}
        message={message}
    />, {
        className: 'p-0',
        toastId: message,
    });
